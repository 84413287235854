import React, { useState, useEffect, useRef } from "react";
import "./QuizDashboard.css";
import BubblePopQuiz from "../BubblePop/BubblePop";
import MultipleChoice from "../MultipleChoice/MultipleChoice";
import FillInTheBlank from "../FillInTheBlank/FillInTheBlank";
import TypingGame from "../TypingGame/TypingGame";
import FourPicsOneWord from "../FourPicOneWord/FourPicOneWord";

const QuizDashboard = () => {
  const [activeQuiz, setActiveQuiz] = useState(null);
  const [muted, setMuted] = useState(false);
  const [showTips, setShowTips] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    createParticles();
    if (audioRef.current && !muted) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      if (activeQuiz || muted) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Error playing audio:", error);
        });
      }
    }
  }, [activeQuiz, muted]);

  const createParticles = () => {
    const container = document.querySelector(".particles");
    for (let i = 0; i < 30; i++) {
      const particle = document.createElement("div");
      particle.classList.add("particle");
      particle.style.left = `${Math.random() * 100}vw`;
      particle.style.animationDuration = `${Math.random() * 5 + 5}s`;
      container.appendChild(particle);
    }
  };

  const handleQuizClick = (quizName) => {
    setActiveQuiz((prevQuiz) => (prevQuiz !== quizName ? quizName : null));
    setShowTips(true); 
  };

  const handleCloseModal = () => {
    setActiveQuiz(null);
    setShowTips(true); 
  };

  const toggleMute = () => {
    setMuted((prevMuted) => !prevMuted);
  };

  const startQuiz = () => {
    setShowTips(false); 
  };

  const quizTips = {
    "Multiple Choice": [
      "Read each option carefully.",
      "Eliminate the obviously incorrect answers first.",
      "Take a moment to confirm your answer before submitting."
    ],
    "Fill in the Blanks": [
      "Focus on understanding the context of the sentence.",
      "Look for clues in the sentence that indicate the missing word.",
      "Think of synonyms if you’re unsure of the exact word."
    ],
    "Bubble Pop Quiz": [
      "Stay focused and try to pop the correct answers quickly.",
      "Watch out for similar-looking options!",
      "Manage your time well; it can go faster than expected."
    ],
    "Four Pics One Word": [
      "Look for similarities among the four images.",
      "Think about words that could represent all four images.",
      "Don’t be afraid to take a guess if you’re unsure."
    ],
    "Typing Game": [
      "Focus on accuracy over speed at the start.",
      "Keep your fingers on the home row to type faster.",
      "Practice regularly to improve your typing speed."
    ]
  };
  
  const renderTips = () => {
    const tips = quizTips[activeQuiz];
    return tips ? (
      <ul className="tips-list">
        {tips.map((tip, index) => (
          <li key={index}>{tip}</li>
        ))}
      </ul>
    ) : (
      <p>Get ready to play!</p>
    );
  };
  
  return (
    <div className={`quiz-container ${activeQuiz ? "blurred" : ""}`}>
      <audio ref={audioRef} loop>
        <source src="/sounds/background-music.mp3" type="audio/mpeg" />
        Your browser does not support the audio tag.
      </audio>
      <div className="particles"></div>

      <button onClick={toggleMute} className="mute-button">
        {muted ? "🔇 " : "🔈 "}
      </button>

      <div className="quizes-container">
        <div onClick={() => handleQuizClick("Multiple Choice")}>Multiple Choice</div>
        <div onClick={() => handleQuizClick("Fill in the Blanks")}>Fill In The Blank</div>
        <div onClick={() => handleQuizClick("Bubble Pop Quiz")}>Bubble Pop</div>
        <div className="quiz-four-pic-btn" onClick={() => handleQuizClick("Four Pics One Word")}>Four Pics One Word</div>
        <div className="type-btn" onClick={() => handleQuizClick("Typing Game")}>Typing</div>
      </div>

      {activeQuiz && (
        <div className="quiz-modal">
          <button className="close-button" onClick={handleCloseModal}>
            ×
          </button>
          <div className="modal-header">{activeQuiz}</div>

          {showTips ? (
            <div className="tips-section">
              <p>Here are some tips for the {activeQuiz}:</p>
              {renderTips()}
              <button onClick={startQuiz} className="start-quiz-button">
                Start Quiz
              </button>
            </div>
          ) : (
            <div className="quiz-content">
              {activeQuiz === "Bubble Pop Quiz" ? (
                <BubblePopQuiz />
              ) : activeQuiz === "Multiple Choice" ? (
                <MultipleChoice />
              ) : activeQuiz === "Fill in the Blanks" ? (
                <FillInTheBlank />
              ) : activeQuiz === "Typing Game" ? (
                <TypingGame />
              ) : activeQuiz === "Four Pics One Word" ? (
                <FourPicsOneWord />
              ) : (
                <p>This is where you play the {activeQuiz} quiz!</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizDashboard;
